import { useEffect, useRef, useState } from 'react';
import { getRecommendedCourses, getUserCoursesMetaData } from './utils/recommended';
import { useDebounce } from './useDebounce';

function useRecommended() {
  const [recommendedCoursesData, setRecommendedCoursesData] = useState([]);
  const [recommendedCoursesLoading, setRecommendedCoursesLoading] =
    useState(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    currentPage: page,
    size: size,
    total: 0,
  });

  const paginate = ({selected}) => {
    setPage(selected + 1);
  }

  useDebounce(() => {
    setRecommendedCoursesLoading(true)
    const query = {
      ...(page>1 ? {
        offset:size*(page-1)
      } : {}),
      limit:size,
      ...(search ? {
        query:search
      } : {})
    }
    getRecommendedCourses(query, getRecommendedCoursesCallback);
  }, 200,[page, search]);

  const getRecommendedCoursesCallback = (data) => {
    setPagination({
      currentPage: page,
      size: size,
      total: data?.total_pages || 0,
    })
    setRecommendedCoursesData(Array.isArray(data?.results) ? data.results.map((_course)=>({
      course:{
        ..._course?.data,
        metadata:{
          ..._course?.data?.edx_data,
        }
      }
    })) : []);
    setTimeout(()=>{
      setRecommendedCoursesLoading(false)
    }, 1000)
  };

  return {
    pagination,
    paginate,
    page,
    recommendedCoursesData,
    recommendedCoursesLoading,
    size,
    setRecommendedCoursesLoading,
    search,
    setSearch
  };
}

export default useRecommended;
