import { getTenant, getUserName } from '../helpers';
import { api } from '@iblai/ibl-web-react-common';

export const getRecommendedCourses = (query={}, callback) => {
  const param = new URLSearchParams(query).toString();
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/search/orgs/${getTenant()}/users/${getUserName()}/recommended/?${param}`;
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('dm_token'),
    },
  }).then((resp) => {
    resp.json().then(callback).catch(()=>callback(null));
  }).catch(callback(null));
}

export function getUserCoursesMetaData(course_id) {
  return new Promise((resolve) => {
    api.ibledxcourses.getCourseMeta({ course_key: course_id }, function (data) {
      resolve(data);
    });
  });
}
