import Coursebox from '../../components/Coursebox/Coursebox';
import React, { useEffect } from 'react';
import useRecommended from '../../hooks/useRecommended';
import { CredentialSearchbar, IBLSpinLoader, NoItemView } from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

const RecommendedViewContent = () => {
  const { t } = useTranslation();
  const {paginate, page, size,
    pagination, recommendedCoursesData, recommendedCoursesLoading, setRecommendedCoursesLoading,
    search, setSearch
  } =
    useRecommended();

  useEffect(() => {
    setRecommendedCoursesLoading(true)
  }, []);
  return (
    <span>
      <div className="panels">
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n\n                ul {\n                list-style: none;\n            }\n\n                .pagination {\n               display: flex;\n    justify-content: flex-end;\n            }\n\n                .pagination .page-number {\n               padding: 2px 10px;\n    border-radius: 4px;\n    font-size: 14px;\n    cursor: pointer;\n    margin: 0 4px;\n    align-items: center;\n    justify-content: center;\n            }\n\n                .pagination .page-number:hover {\n                background-image: linear-gradient(90deg, #4a78b8, #6cafe1);\n    color: #fff;\n            }\n\n                .pagination .active {\n               background-image: linear-gradient(90deg, #4a78b8, #6cafe1);\n    color: #fff;\n            }\n\n                .pagination .active:hover {\n                color: #00a8ff;\n                background: #fff;\n            }\n            ',
          }}
        />
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left" style={{ display: 'none' }}>
                    <div className="prof_box browse">
                      <div className="text-block sec_title ex_co" no-mapping="">
                        Add Content
                      </div>
                      <div className="text-block sec_title fb" no-mapping="">
                        Filter By
                      </div>
                    </div>
                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>

                  <div className="br_panel" data-name="top" style={{ width: '100%' }}>
                    <div style={{marginBottom:"10px"}}>
                      <div className="text-block-308">{t('Recommended for Me')}</div>
                    <CredentialSearchbar searchTerm={search} setSearchTerm={setSearch} />
                    </div>
                    {recommendedCoursesLoading ? (
                      <IBLSpinLoader size={40} containerHeight={'400px'} />
                    ) :
                      (
                        recommendedCoursesData.length > 0 ?
                          (
                            <div className="div-block-430 sl pathway_grid">
                              <Coursebox
                                userCourses={recommendedCoursesData || []}
                                userCoursesLoaded={!recommendedCoursesLoading}
                                linkPrefix={'/courses/'}
                              ></Coursebox>
                            </div>
                          )
                          :
                          <NoItemView displayMessage={t("No Recommended Courses Available")} />
                      )
                        }
                    {!recommendedCoursesLoading && pagination.total > 1 && (
                      <ReactPaginate
                        onPageChange={paginate}
                        pageCount={pagination.total}
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        containerClassName={'pagination m-t-20'}
                        pageLinkClassName={'page-number'}
                        previousLinkClassName={'page-number'}
                        nextLinkClassName={'page-number'}
                        activeLinkClassName={'active'}
                        forcePage={page - 1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default RecommendedViewContent;
