import { api, inIframe } from '@iblai/ibl-web-react-common';
import { getUserName } from '../hooks/helpers';
import { json } from 'react-router-dom';

export const authGuard = (
  shouldBeAdmin,
  shouldBeLoggedIn,
  appData,
  setAppData
) => {
  return async () => {
    if (!shouldBeLoggedIn) {
      return [];
    }
    if (shouldBeLoggedIn && (!appData?.axd_token || !appData?.dm_token)) {
      if (!inIframe()) {
        throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
          status: 401,
        });
      } else {
        return [];
      }
    }
    const getUserMetadataPromise = new Promise((_resolve, _reject) => {
      if (!Object.keys(appData.userMetaData).length) {
        api.ibledxusers.getUsersManageMetadata(
          { username: getUserName() },
          function (data) {
            _resolve({
              userMetaData: { ...data },
              userMetaDataLoaded: true,
            });
          },
          () => {
            _reject();
          }
        );
      } else {
        _resolve({});
      }
    });
    const getTenantsPromise = new Promise((_resolve, _reject) => {
      const selectedTenant = localStorage.getItem('tenant');
      if (!localStorage.getItem('tenants')) {
        api.ibledxtenants.getUserTenants((tenants) => {
          api.iblutils.saveUserTenantsDataToLocalStorage(
            tenants,
            selectedTenant
          );
          _resolve({
            current_tenant: localStorage.getItem('current_tenant'),
            tenants: localStorage.getItem('tenants'),
          });
        });
      } else {
        let currentTenant = localStorage.getItem('current_tenant');
        let currentTenantKey;
        try {
          currentTenantKey = JSON.parse(currentTenant)?.key;
        } catch (e) {}

        api.iblutils.saveUserTenantsDataToLocalStorage(
          JSON.parse(localStorage.getItem('tenants')),
          selectedTenant
        );

        // Getting the new tenant after setting

        currentTenant = JSON.parse(localStorage.getItem('current_tenant'));
        if (currentTenantKey !== currentTenant.key) {
          _resolve({
            current_tenant: localStorage.getItem('current_tenant'),
            tenants: localStorage.getItem('tenants'),
          });
        } else {
          _resolve({});
        }
      }
    });
    try {
      const [_userMetaDataInfo, _tenantInfo] = await Promise.all([
        getUserMetadataPromise,
        getTenantsPromise,
      ]);
      const currentTenantData = JSON.parse(localStorage.current_tenant);
      if (shouldBeAdmin && currentTenantData.is_admin) {
        return json({}, { status: 403 });
      }

      if (
        Object.keys(_userMetaDataInfo)?.length ||
        Object.keys(_tenantInfo)?.length
      ) {
        setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
      }
      const currentTenant = JSON.parse(localStorage.getItem('current_tenant'));
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData?.user_nicename !== currentTenant?.username) {
        return json({ shouldBeLoggedIn: true }, { status: 401 });
      }
      let domain = '';
      try {
        domain = await api.ibldmplatform.getPlatformCustomDomain(
          'skills',
          localStorage.getItem('tenant')
        );
      } catch (error) {
        console.error('Error fetching custom domain:', error);
      }
      if (domain && domain !== window.location.hostname) {
        window.location.href = `https://${domain}`;
      }
      return json({}, { status: 200 });
    } catch (error) {
      return json({}, { status: 500 });
    }
  };
};
