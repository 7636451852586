import { api } from '@iblai/ibl-web-react-common';
import { getUserName } from '../helpers';

export function getIframeURL(course_id, courseInfo, callback) {
  //check if the courseInfo is an object, includes : or a string
  if (typeof courseInfo === 'object' || courseInfo.includes(':')) {
    flattenVerticalBlocks(courseInfo);
    let unit = getUnitToIframe(courseInfo);
    let sequentialID = findSequentialParent(courseInfo, unit.id);
    addIframeUrl(course_id, unit.id, callback, sequentialID);
  } else {
    addIframeUrl(course_id, courseInfo, callback);
  }
}

export function findSequentialParent(data, verticalId) {
  // Base case: if the current data block is of type 'sequential' and has children
  if (data.type === 'sequential' && data.children) {
    for (const child of data.children) {
      // Check if the child is the vertical block we are looking for
      if (child.id === verticalId) {
        return data.id; // Return the ID of the sequential block
      }
      // Recursively search deeper if the child is not the vertical block
      const foundId = findSequentialParent(child, verticalId);
      if (foundId) {
        return foundId; // Return the found ID if present
      }
    }
  } else if (data.children) {
    // Continue recursion if the block is not 'sequential' but has children
    for (const child of data.children) {
      const foundId = findSequentialParent(child, verticalId);
      if (foundId) {
        return foundId;
      }
    }
  }
  // Return null if no matching sequential parent is found at this level
  return null;
}

function flattenVerticalBlocks(data) {
  if (!data || typeof data !== 'object') {
    return [];
  }

  if (Array.isArray(data)) {
    const result = [];
    for (const item of data) {
      const flattenedItems = flattenVerticalBlocks(item);
      result.push(...flattenedItems);
    }
    return result;
  }

  if (data.type === 'vertical') {
    const block = {
      id: data.id,
      display_name: data.display_name,
    };

    const children = flattenVerticalBlocks(data.children);
    return [block, ...children];
  }

  return flattenVerticalBlocks(data.children);
}

export function getFirstAvailableUnit(data, maxAttempts = 2) {
  try {
    // Try to find the first available unit within the specified maxAttempts
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      let element = data.children[0]?.children[0]?.children[attempt];
      if (element) return element; // Return the element if found
    }
  } catch (e) {
    // In case of any error, safely return the upper level element if available
    if (
      data.hasOwnProperty('children') &&
      data.children[0].hasOwnProperty('children') &&
      new Date(data.start) < new Date()
    ) {
      return data.children[0]?.children[0];
    } else {
      throw new Error(
        'Course has no content or course has not been started yet'
      );
    }
  }

  return null; // Return null if no element is found after attempts
}

export function findLastResumeBlock(courseData) {
  let lastResumeBlock = null;

  // Helper function to recursively traverse the tree
  function traverse(node) {
    // If the current node has resume_block = true and no children, update the result
    if (node.resume_block && node.type === 'vertical') {
      lastResumeBlock = node;
    }

    // Traverse the children
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        traverse(child);
      }
    }
  }

  // Start traversing from the root node
  traverse(courseData);

  return lastResumeBlock;
}

function getUnitToIframe(courseOutlineData) {
  //     decide if we have been given an explicit block to iframe
  const courseUrl = new URL(window.location.href);
  if (courseUrl.searchParams.has('unit_id')) {
    const unitId = courseUrl.search.match(/unit_id=([^&]*)/)[1];
    if (unitId === 'null') {
      return getFirstAvailableUnit(courseOutlineData);
    }

    return findVerticalById(courseOutlineData, unitId);
  } else {
    let lastResumeBlock = findLastResumeBlock(courseOutlineData);

    if (lastResumeBlock) {
      return lastResumeBlock;
    }
  }

  return getFirstAvailableUnit(courseOutlineData);
}

async function addIframeUrl(
  course_id,
  xblockID,
  callback,
  sequentialID = null
) {
  // let data = {
  //   course_id: course_id,
  //   xblock_id: xblockID,
  //   mfe_url: process.env.REACT_APP_IBL_MFE_URL,
  //   sequential_id: sequentialID,
  // };

  let url = '';
  xblockID = xblockID.replace(/^\/|\/$/g, '');

  switch (xblockID) {
    case 'forum':
      url = `${process.env.REACT_APP_IBL_MFE_URL}discussions/${course_id}/posts`;
      break;
    case 'notes':
      url = `${process.env.REACT_APP_IBL_MFE_URL}courses/${course_id}/edxnotes`;
      break;
    case 'instructor':
      url = `${process.env.REACT_APP_IBL_MFE_URL}courses/${course_id}/instructor`;
      break;
    case 'progress':
      url = `${process.env.REACT_APP_IBL_MFE_URL}/learning/course/${course_id}/progress/`;
      break;
    case 'dates':
      url = `${process.env.REACT_APP_IBL_MFE_URL}/learning/course/${course_id}/dates/`;
      break;
    default:
      url = `${process.env.REACT_APP_IBL_LMS_URL}/xblock/${xblockID}?show_title=0&show_bookmark_button=1&recheck_access=1&view=student_view`;
      const authSsoToken = await getEdxSsoAuthToken(url);
      callback(
        `${process.env.REACT_APP_IBL_LMS_URL}/ibl/ai/sso/backend/edx/iframe?sso_auth_token=${authSsoToken}`
      );
      return; // Prevent further execution
  }
  callback(url);
}

function findVerticalById(data, verticalId) {
  // Define a recursive helper function to search through the data
  function search(data) {
    for (const item of data) {
      if (item.id === verticalId) {
        return item;
      }
      if (item.children) {
        const result = search(item.children);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  // Call the helper function starting from the top level
  return search(data.children);
}

export const getParentBlockById = (blocksArray, targetBlockId) => {
  let foundIndices = [];

  const findParentBlock = (currentBlock, targetBlockId, currentIndices) => {
    if (currentBlock.id === targetBlockId) {
      foundIndices = currentIndices.slice(); // Copy the current indices
      return currentBlock;
    }

    if (currentBlock.children) {
      for (let i = 0; i < currentBlock.children.length; i++) {
        const childBlock = currentBlock.children[i];
        const result = findParentBlock(childBlock, targetBlockId, [
          ...currentIndices,
          i,
        ]);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  for (let i = 0; i < blocksArray.length; i++) {
    const rootBlock = blocksArray[i];
    const parentBlock = findParentBlock(rootBlock, targetBlockId, [i]);
    if (parentBlock) {
      return { parentBlock, foundIndices };
    }
  }

  return { parentBlock: null, foundIndices };
};

export function getPreviousUnitIframe(suppliedId, courseData) {
  let idList = flattenVerticalBlocks(courseData);
  const index = idList.findIndex((item) => {
    return item.id === suppliedId;
  });

  if (index === -1 || index === 0) {
    // If the suppliedId is not found or it's the first element, return null
    return null;
  }

  return idList[index - 1].id;
}

export function getNextUnitIframe(suppliedId, courseData) {
  let idList = flattenVerticalBlocks(courseData);
  const index = idList.findIndex((item) => item.id === suppliedId);

  if (index === -1 || index === idList.length - 1) {
    // If the suppliedId is not found or it's the last element, return null
    return null;
  }

  return idList[index + 1].id;
}

function addBookmarksTab(tabs, course_id) {
  const newTab = {
    tab_id: 'bookmarks',
    title: 'Bookmarks',
    url: `${process.env.REACT_APP_IBL_LMS_URL}/courses/${course_id}/bookmarks`,
  };
  tabs.push(newTab);
}

export function getCourseTabs(courseID, callback) {
  let data = {
    course_id: courseID,
  };
  api.ibledxcourses.getCourseTabs(data, function (data) {
    let tabsArray = data.tabs;
    addBookmarksTab(tabsArray, data.course_id);
    callback(tabsArray);
  });
}

export async function getEdxSsoAuthToken(redirect_url) {
  const headers = {};
  if (localStorage.getItem('edx_jwt_token')) {
    headers['Authorization'] = `JWT ${localStorage.getItem('edx_jwt_token')}`;
  }
  const response = await fetch(
    `${process.env.REACT_APP_IBL_LMS_URL}/ibl/ai/sso/backend/edx/sso-auth-token/generate`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify({
        username: getUserName(),
        redirect_url: redirect_url,
      }),
    }
  );
  if (!response.ok) {
    throw new Error('Failed to fetch SSO auth token');
  }
  const data = await response.json();
  return data.sso_auth_token;
}
